export default [
  {
    title: 'Beranda',
    route: 'dashboard',
    icon: 'GridIcon',
    permission: '',
  },
  {
    title: 'Darurat',
    route: 'emergencies',
    icon: 'AlertCircleIcon',
    permission: '',
  },
  {
    title: 'Permintaan',
    route: 'dashboard',
    icon: 'RadioIcon',
    permission: '',
    children : [
      {
        title: 'Towing',
        route: 'request-towings',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'Penggantian Ban & Aki',
        route: 'request-part-replacements',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      // {
      //   title: 'Penggantian Unit',
      //   route: 'request-unit-replacements',
      //   icon: 'ArrowRightIcon',
      //   permission: '',
      // },
      {
        title: 'Service',
        route: 'request-services',
        icon: 'ArrowRightIcon',
        permission: '',
      },
    ]
  },
  {
    title: 'Penawaran',
    route: 'request-quotations',
    icon: 'FileTextIcon',
    permission: '',
  },
  {
    title: 'Kontrak',
    route: 'contracts',
    icon: 'FileTextIcon',
    permission: '',
  },
  {
    title: 'Pelanggan',
    route: 'customers',
    icon: 'UsersIcon',
    permission: '',
  },
  {
    title: 'Laporan',
    route: 'reports',
    icon: 'FileTextIcon',
    permission: '',
  },
  {
    title: 'Unit',
    icon: 'TruckIcon',
    permission: '',
    children: [
      {
        title: 'Kategori',
        route: 'unit-categories',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'Unit',
        route: 'units',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'Katalog',
        route: 'catalogs',
        icon: 'BookOpenIcon',
        permission: '',
      },
    ]
  },
  {
    title: 'Tempat',
    route: 'dashboard',
    icon: 'MapPinIcon',
    permission: '',
    children : [
      {
        title: 'Bengkel',
        route: 'place-workshops',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'SPKLU',
        route: 'place-charging-stations',
        icon: 'ArrowRightIcon',
        permission: '',
      },
    ]
  },
  {
    title: 'Pengaturan',
    route: 'dashboard',
    icon: 'SettingsIcon',
    permission: '',
    children : [
      {
        title: 'Pengingat',
        route: 'reminder',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'Promosi',
        route: 'promotions',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'Dokumen',
        route: 'documents',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'Kontak',
        route: 'contacts',
        icon: 'ArrowRightIcon',
        permission: '',
      },
      {
        title: 'Tema',
        route: 'theme',
        icon: 'ArrowRightIcon',
        permission: '',
      },
    ]
  },
]